import React from 'react';
import { createPortal } from 'react-dom';
import { ChatContainer } from '../../components';
import { useChatContext } from '../../components/chat_provider/ChatModuleContext';
import { useStyles } from './FullscreenChat.styles';

export function FullScreenChat() {
  const { open } = useChatContext();
  const styles = useStyles();
  if (!open) return null;
  return createPortal(
    <div className={styles.container}>
      <ChatContainer setDisabledDragging={() => {}} />
    </div>,
    document.body
  );
}

export default FullScreenChat;
