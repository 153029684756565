import { IPreviousMessage } from "./types";
const API_ROOT_ENDPOINT = "https://draide-dashboard-copilot-jav.aiscaler.net"

async function sendChatMessage(
  text: string,
  previousMessages?: IPreviousMessage[]
): Promise<any> {
  const url = `${API_ROOT_ENDPOINT}/api/v1/chat`;
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Accept", "text/event-stream");

  const raw = JSON.stringify({
    ask: text,
  });

  const response = await fetch(url, {
    method: "POST",
    headers: headers,
    body: raw,
    redirect: "follow",
  });

  const errMessage = "There is an error occurred! Please try again later";

  if (!response.ok) throw new Error(errMessage);

  return response.body;
}

export const chatApi = { sendChatMessage };
