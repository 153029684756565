/*
 * File: vi.ts
 * Project: draid-chat
 * File Created: Wednesday, 19th July 2023 10:45:29 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2023 VinBrain JSC
 */

export const vi = {
  draidChatBot: "DrAid™ Copilot",
  welcomeMessage: "Chào mừng đến với DrAid™ Copilot, Tôi có thể giúp được gì cho bạn?",
  chatPlaceholder: "Nhập tin nhắn",
  draidTyping: "DrAid™ đang trả lời",
  draidChatLoading: "Đang tải dữ liệu...",
  chat_gpt_disclaimer:
    "Thông tin do Chatbot cung cấp chỉ mang tính chất tham khảo",
  message_intro: "Tôi có thể giúp gì cho bạn?",
  chat_gpt_disclaimer_short: "Thông tin chỉ mang tính chất tham khảo, xin hãy tham vấn thêm với các chuyên gia",

  error_message: "Đã có lỗi xảy ra! Vui lòng thử lại sau.",
  summarization_header: "TÓM TẮT THÔNG TIN BỆNH NHÂN",
  tooltip_copy: "Sao chép",
  tooltip_copied: "Đã sao chép",
  tooltip_like: "Thích",
  tooltip_dislike: "Không thích",
};
