import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(() => ({
  container: {
    zIndex: 50,
    borderRadius: 8,
    width: "100%",
    height: "100%",
    marginBottom: "8px",
    overflow: "hidden",
    backgroundColor: "#F0F2F6",
    display: "flex",
    flexDirection: "column",
    boxShadow:
      "rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px",
  },
  disclaimer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#717171",
    fontSize: "0.875rem",
    padding: "4px 12px",
  },
}));
