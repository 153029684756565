import * as React from 'react';
import Box from "@mui/material/Box";

const PageNotFound = () => {
    return (
        <Box
            component="main"
            style={{
                backgroundColor: '#132635',
                height: 'calc(100vh)',
            }}
        >
            <h1 style={{
                color: "#D1DBDF",
                textAlign: "center",
                margin: 0,
                padding: 50
            }}
            >
                404 - Page not found!
            </h1>
        </Box>
    );
}

export default PageNotFound;