import React from "react";
import { useStyles } from "./ChatSuggestion.styles";
import { VscArrowRight } from "react-icons/vsc";
import { MdOutlineKeyboardDoubleArrowDown } from "react-icons/md";
import { Tooltip } from "@material-ui/core";

interface Props {
  suggestions?: string[];
  onSelect?(text: string): void;
  onClose?(): void;
}

export function ChatSuggesstion({
  onSelect,
  onClose,
  suggestions = [
    "Tổng số lượng bệnh nhân đến khám 6 tháng đầu năm 2023?",
    "Top 5 loại thuốc sử dụng nhiều nhất?",
    "Doanh thu 6 tháng đầu năm 2023?",
    "Top 3 bệnh chính năm 2023?",
  ],
}: Props) {
  const classes = useStyles();

  return (
    <div>
      <Tooltip
        arrow
        title="Đóng"
        PopperProps={{
          style: { zIndex: 99999 },
          disablePortal: true,
        }}
        placement="bottom"
      >
        <div
          className={classes.btnCloseContainer}
          onClick={(evt) => {
            evt.preventDefault();
            evt.stopPropagation();
            onClose && onClose();
          }}
        >
          <MdOutlineKeyboardDoubleArrowDown size={16} />
        </div>
      </Tooltip>

      {suggestions.map((text) => {
        return (
          <div
            className={classes.suggestionItemContainer}
            key={text}
            onClick={(evt) => {
              evt.preventDefault();
              evt.stopPropagation();
              onSelect && onSelect(text);
            }}
          >
            <span className={classes.suggestionItemText}>{text}</span>
            <VscArrowRight size={20} className={classes.suggestionItemButton} />
          </div>
        );
      })}
    </div>
  );
}

export default ChatSuggesstion;
