import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(() => ({
  box: {
    position: "fixed",
    right: 28,
    bottom: 28,
    zIndex: 999,
  },
  bubbleChat: {
    all: "unset",
    fontFamily: "Arial, Helvetica, sans-serif",
    lineHeight: 1.4,
    position: "relative",
    right: 0,
    bottom: 0,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
  },
}));
