import * as React from 'react';

const Error = () => {
    return (
        <h1 style={{
            color: "#F04864",
            textAlign: "center",
            margin: 0,
            padding: 50
        }}
        >
            An error occurred. Please try again later!
        </h1>
    );
}

export default Error;