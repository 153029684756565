export default class ColorUtils {

    static generateRandomColor() {
        const r = Math.floor(Math.random() * 256);
        const g = Math.floor(Math.random() * 256);
        const b = Math.floor(Math.random() * 256);

        return `#${r.toString(16).padStart(2, "0")}${g.toString(16).padStart(2, "0")}${b.toString(16).padStart(2, "0")}`;
    }

    static generateRandomColorsArray(count: number) {
        const colorsArray: string[] = [];
        while (colorsArray.length < count) {
            const color = ColorUtils.generateRandomColor();

            const isToneMatching = colorsArray.some(existingColor => {
                const diffR = Math.abs(parseInt(existingColor.slice(1, 3), 16) - parseInt(color.slice(1, 3), 16));
                const diffG = Math.abs(parseInt(existingColor.slice(3, 5), 16) - parseInt(color.slice(3, 5), 16));
                const diffB = Math.abs(parseInt(existingColor.slice(5, 7), 16) - parseInt(color.slice(5, 7), 16));

                return diffR < 32 && diffG < 32 && diffB < 32
            });

            if (!isToneMatching) {
                colorsArray.push(color);
            }
        }
        return colorsArray;
    }
}