import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  container: (props: any) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 32,
    height: 32,
    overflow: 'hidden',
    borderRadius: 16,
    backgroundColor: props?.showAvatar ? 'transparent' : '#ffffff00',
    flex: 'none'
  })
}));
