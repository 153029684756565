import { ChatProvider } from '.';
import { BubbleChat } from '../modules/bubble_chat/BubbleChat';
import { CustomChat } from '../modules/custom_chat/CustomChat';
import { FullScreenChat } from '../modules/fullscreen_chat/FullscreenChat';
import { IChatOptions } from '../types';

export interface ModuleChatProps {
  options: IChatOptions;
  onClose(): void;
}

export function ModuleChat({ options, onClose }: ModuleChatProps) {
  const { displayMode } = options;
  return (
    <ChatProvider options={options} onClose={onClose}>
      <>
        {displayMode === 'bubble' && <BubbleChat />}
        {displayMode === 'fullscreen' && <FullScreenChat />}
        {displayMode === 'custom' && <CustomChat />}
      </>
    </ChatProvider>
  );
}

export default ModuleChat;
