import React, { ReactElement } from "react";
import { RecordingContext } from "./RecordingState";
import useRecordingState from "./useRecordingState";

interface Props {
  children: ReactElement;
}

export const SpeechToTextProvider = ({ children }: Props) => (
  <RecordingContext.Provider value={useRecordingState()}>
    {children}
  </RecordingContext.Provider>
);

export default SpeechToTextProvider;
