import { useChatContext } from "../chat_provider/ChatModuleContext";
import { useStyles } from "./ChatHeader.styles";
import ChatBotAvatar from "../icons/chat-bot-avatar";
import { VscChevronDown } from "react-icons/vsc";
import { useTranslation } from "../../i18n";
interface ChatHeaderProps {
  setDisabledDragging: Function;
  onClickToggleSize?(): void;
}

export function ChatHeader({
  setDisabledDragging,
  onClickToggleSize,
}: ChatHeaderProps) {
  const { t } = useTranslation();
  const styles = useStyles();
  const { toggleChat } = useChatContext();
  return (
    <div
      className={`${styles.container} draid-drag-handle`}
      onMouseOver={() => {
        setDisabledDragging(false);
      }}
      onMouseOut={() => {
        setDisabledDragging(true);
      }}
    >
      <div className={styles.logo}>
        <ChatBotAvatar />
      </div>
      <h3 className={styles.title}>{t("draidChatBot")}</h3>
      <button className={styles.headerBtn} onClick={onClickToggleSize}>
        <svg
          width="24"
          height="24"
          viewBox="0 0 28 28"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M25.75 5.75V21.5C25.75 21.9641 25.5656 22.4092 25.2374 22.7374C24.9092 23.0656 24.4641 23.25 24 23.25H4.75C4.28587 23.25 3.84075 23.0656 3.51256 22.7374C3.18437 22.4092 3 21.9641 3 21.5V5.75C3 5.28587 3.18437 4.84075 3.51256 4.51256C3.84075 4.18437 4.28587 4 4.75 4H24C24.4641 4 24.9092 4.18437 25.2374 4.51256C25.5656 4.84075 25.75 5.28587 25.75 5.75ZM24 21.5V5.75H4.75V21.5H24Z"
            fill="white"
          />
          <path
            d="M22.8244 8.02478L19.0482 11.8001H21.1999C21.359 11.8001 21.5116 11.8633 21.6241 11.9758C21.7367 12.0883 21.7999 12.2409 21.7999 12.4C21.7999 12.5592 21.7367 12.7118 21.6241 12.8243C21.5116 12.9368 21.359 13 21.1999 13H17.6C17.4409 13 17.2882 12.9368 17.1757 12.8243C17.0632 12.7118 17 12.5592 17 12.4V8.80023C17 8.64111 17.0632 8.48851 17.1757 8.37599C17.2882 8.26348 17.4409 8.20027 17.6 8.20027C17.7591 8.20027 17.9117 8.26348 18.0242 8.37599C18.1368 8.48851 18.2 8.64111 18.2 8.80023V10.9519L21.9754 7.17582C22.088 7.06325 22.2406 7 22.3999 7C22.5591 7 22.7118 7.06325 22.8244 7.17582C22.9369 7.2884 23.0002 7.44109 23.0002 7.6003C23.0002 7.75951 22.9369 7.9122 22.8244 8.02478Z"
            fill="white"
          />
          <path
            d="M11.4002 14H7.80029C7.64116 14 7.48855 14.0632 7.37604 14.1757C7.26352 14.2882 7.2003 14.4408 7.2003 14.6C7.2003 14.7591 7.26352 14.9117 7.37604 15.0242C7.48855 15.1367 7.64116 15.1999 7.80029 15.1999H9.95198L6.17583 18.9752C6.06325 19.0878 6 19.2405 6 19.3997C6 19.5589 6.06325 19.7116 6.17583 19.8242C6.28841 19.9368 6.4411 20 6.60032 20C6.75953 20 6.91223 19.9368 7.02481 19.8242L10.8002 16.0481V18.1998C10.8002 18.3589 10.8634 18.5115 10.9759 18.624C11.0885 18.7365 11.2411 18.7997 11.4002 18.7997C11.5593 18.7997 11.7119 18.7365 11.8245 18.624C11.937 18.5115 12.0002 18.3589 12.0002 18.1998V14.6C12.0002 14.4408 11.937 14.2882 11.8245 14.1757C11.7119 14.0632 11.5593 14 11.4002 14Z"
            fill="white"
          />
        </svg>
      </button>
      <button className={styles.headerBtn} type="button" onClick={toggleChat}>
        <VscChevronDown color="#fff" size={24} />
      </button>
    </div>
  );
}

export default ChatHeader;
