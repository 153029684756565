/*
 * File: ChatContainer.tsx
 * Project: draid-chat
 * File Created: Wednesday, 19th July 2023 10:27:04 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2023 VinBrain JSC
 */
import InfoIcon from "@material-ui/icons/Info";
import { ResizableBox } from "react-resizable";
import "react-resizable/css/styles.css";
import { ChatHeader } from "../chat_header/ChatHeader";
import { ChatInput } from "../chat_input/ChatInput";
import { ChatMessages } from "../chat_messages/ChatMessages";
import { useStyles } from "./ChatContainer.styles";
import { useTranslation } from "../../i18n";
import { useState } from "react";
import { SpeechToTextProvider } from "../../../feature-speech-to-text";

interface ChatContainerProps {
  setDisabledDragging: Function;
  width?: number;
  height?: number;
}

export function ChatContainer({
  setDisabledDragging,
  width = 400,
  height = 500,
}: ChatContainerProps) {
  const { t } = useTranslation();
  const styles = useStyles();
  const [defaultSize, setDefaultSize] = useState(() => {
    try {
      const str = localStorage.getItem("draid-chat-size");
      if (str) {
        return JSON.parse(str);
      }
    } catch (error) {}
    return { width, height };
  });

  function handleSizeChange() {
    if (defaultSize.width === width && defaultSize.height === height) {
      const customWidth = window.innerWidth * 0.6;
      const customHeight = window.innerHeight * 0.8;
      const customSize = { width: customWidth, height: customHeight };
      setDefaultSize(customSize);
      localStorage.setItem("draid-chat-size", JSON.stringify(customSize));
    } else {
      const customSize = { width, height };
      setDefaultSize(customSize);
      localStorage.setItem("draid-chat-size", JSON.stringify(customSize));
    }
  }

  return (
    <ResizableBox
      onResizeStart={(evt) => {
        evt.preventDefault();
        evt.stopPropagation();
        const container = document.getElementById("root");
        if (container) {
          container.style.pointerEvents = "none";
        }
      }}
      onResizeStop={(evt) => {
        evt.preventDefault();
        evt.stopPropagation();
        const container = document.getElementById("root");
        if (container) {
          container.style.pointerEvents = "auto";
        }
      }}
      onResize={(evt, data) => {
        evt.preventDefault();
        evt.stopPropagation();
        localStorage.setItem("draid-chat-size", JSON.stringify(data.size));
      }}
      height={defaultSize.height}
      width={defaultSize.width}
      resizeHandles={["nw", "w", "n"]}
      minConstraints={[300, 300]}
    >
      <div className={styles.container}>
        <ChatHeader
          setDisabledDragging={setDisabledDragging}
          onClickToggleSize={handleSizeChange}
        />
        <ChatMessages />
        <div className={styles.disclaimer}>
          <InfoIcon style={{ fontSize: 24, padding: 5 }} />
          {t("chat_gpt_disclaimer_short")}
        </div>
        <SpeechToTextProvider>
          <ChatInput />
        </SpeechToTextProvider>
      </div>
    </ResizableBox>
  );
}

export default ChatContainer;
