import { useState } from "react";
import Draggable from "react-draggable";
import { createPortal } from "react-dom";
import { ChatContainer } from "../../components";
import { useChatContext } from "../../components/chat_provider/ChatModuleContext";
import { Bubble } from "./bubble/BubbleComponent";
import { useStyles } from "./BubbleChat.styles";
import { useKeyPress } from "ahooks";

export function BubbleChat() {
  const { open, toggleChat } = useChatContext();
  const [dragging, setDragging] = useState(false);
  const [disabledDragging, setDisabledDragging] = useState(true);

  const styles = useStyles();

  useKeyPress("Escape", () => {
    toggleChat();
  });

  return createPortal(
    // @ts-ignore
    <Draggable
      defaultClassName="draid-container"
      disabled={disabledDragging}
      onDrag={() => {
        setDragging(true);
      }}
      onStop={() => {
        setTimeout(() => setDragging(false), 0);
      }}
    >
      <div className={styles.box}>
        <div className={styles.bubbleChat}>
          {open && <ChatContainer setDisabledDragging={setDisabledDragging} />}
          <Bubble
            onClick={toggleChat}
            open={open}
            dragging={dragging}
            setDisabledDragging={setDisabledDragging}
          />
        </div>
      </div>
    </Draggable>,
    document.body
  );
}

export default BubbleChat;
