import { ReactNode, useCallback, useEffect, useState } from "react";
import {
  IChatMessage,
  IChatUser,
  IChatOptions,
} from "../../types";
import { ChatModuleContext } from "./ChatModuleContext";
import { useChats } from "./useChats";

export interface ChatProviderProps {
  children?: ReactNode;
  options: IChatOptions;
  onClose(): void;
}

export function ChatProvider({
  children,
  options,
  onClose,
}: ChatProviderProps) {
  const [open, setOpen] = useState(options.open);
  const [loading, setLoading] = useState(true);
  const [sending, setSending] = useState(false);
  const [typing] = useState(false);

  const [participants, setParticipants] = useState<IChatUser[]>([]);
  const [messages, setMessages] = useState<IChatMessage[]>([]);

  useChats(options);

  const onMessagesLoaded = useCallback(
    (data: { messages: IChatMessage[]; participants: IChatUser[] }) => {
      setMessages(data.messages);
      setParticipants(data.participants);
      setLoading(false);
    },
    []
  );

  const onNewMessage = useCallback((message: IChatMessage) => {
    setMessages((oldMessages) => [...oldMessages, message]);
    setSending(false);
  }, []);

  const onAppendMessage = useCallback((message: IChatMessage) => {
    setMessages((oldMessages) => {
      const currentMessage = oldMessages.find((mss) => mss.id === message.id);

      const currentMessages = [...oldMessages];

      if (!currentMessage) {
        currentMessages.push({ ...message });
        return currentMessages;
      }

      return currentMessages.map((item) => {
        if (item.id === message.id) {
          return { ...item, text: item.text + message.text };
        }
        return item;
      });
    });
    setSending(false);
  }, []);

  const onCompleteMessageStream = useCallback((message: IChatMessage) => {
    setMessages((items) => {
      return items.map((item) => {
        if (item.id === message.id) {
          return { ...item, isCompleted: true };
        }
        return item;
      });
    });
    setSending(false);
  }, []);

  function toggleChat() {
    setOpen(!open);
    if (open) onClose();
  }

  function sendMessage(message: string) {
    setSending(true);
    const newMessage = {
      id: `message-${messages.length}${1}`,
      text: message,
      sender: participants[1],
      sendAt: new Date(),
      content: [],
    };
    setMessages((oldMessages) => [...oldMessages, newMessage]);

    const eventData = {
      command: "USER_SEND_MESSAGE",
      data: newMessage,
      previousMessages: messages.slice(2).map((m) => ({
        message: m.text,
        isMe: m.sender?.isMe,
        sendAt: m.sendAt?.getTime(),
      })),
    };
    const event = new CustomEvent("MODULE_CHAT_EVENT", {
      detail: eventData,
    });
    document.dispatchEvent(event);
  }

  useEffect(() => {
    function handleEvent(event: any) {
      const { command, data } = event.detail;
      if (!open) {
        toggleChat();
      }
      if (command === "OUTSIDE_MESSAGE") {
        const { message } = data;
        setTimeout(() => sendMessage(message), 0);
      }
    }
    document.addEventListener("RECEIVED_MESSAGE_EVENT", handleEvent);
    return () => {
      document.removeEventListener("RECEIVED_MESSAGE_EVENT", handleEvent);
    };
  }, [participants, messages, open]);

  useEffect(() => {
    const handleModuleChatEvent = (event: any) => {
      const { command, data } = event.detail;
      if (command === "MESSAGES_LOADED") {
        onMessagesLoaded(data);
      } else if (command === "NEW_MESSAGE") {
        onNewMessage(data);
      } else if (command === "NEW_MESSAGE_STREAM") {
        onAppendMessage(data);
      } else if (command === "COMPLETE_MESSAGE_STREAM") {
        onCompleteMessageStream(data);
      }
    };

    document.addEventListener("MODULE_CHAT_EVENT", handleModuleChatEvent);

    return () => {
      document.removeEventListener("MODULE_CHAT_EVENT", handleModuleChatEvent);
    };
  }, [
    onMessagesLoaded,
    onNewMessage,
    onAppendMessage,
    onCompleteMessageStream,
  ]);

  useEffect(() => {
    setOpen(options.open);
  }, [options]);

  const state = {
    open,
    loading,
    sending,
    typing,
    messages,
    participants,
    options,
    toggleChat,
    sendMessage,
    onMessagesLoaded,
    onNewMessage,
  };

  return (
    <ChatModuleContext.Provider value={state}>
      {children}
    </ChatModuleContext.Provider>
  );
}

export default ChatProvider;
