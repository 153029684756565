export const DRAID_CUSTOM_EVENT = "MODULE_CHAT_EVENT";

export const ChatEvents = {
  NEW_MESSAGE: "NEW_MESSAGE",
  USER_SEND_MESSAGE: "USER_SEND_MESSAGE",
  USER_SEND_MESSAGE_V4: "USER_SEND_MESSAGE_V4",
  START_SUMMARIZATION: "START_SUMMARIZATION",
  NEW_MESSAGE_STREAM: "NEW_MESSAGE_STREAM",
  NEW_MESSAGE_REFERENCE: "NEW_MESSAGE_REFERENCE",
  COMPLETE_MESSAGE_STREAM: "COMPLETE_MESSAGE_STREAM",
  MESSAGE_STREAM_ERROR: "MESSAGE_STREAM_ERROR",
  MESSAGES_LOADED: "MESSAGES_LOADED",
  SELECT_PATIENT: "SELECT_PATIENT",
  SELECT_VISIT: "SELECT_VISIT",
  OPEN_SELECT_PATIENT: "OPEN_SELECT_PATIENT",
  OPEN_SELECT_VISIT: "OPEN_SELECT_VISIT",
  SCROLL_TO_END: "SCROLL_TO_END",
};
