import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(() => ({
  bubbleChatContainer: {
    width: "250px",
    margin: "0px 0px 0px 0px",
    height: "70px",
    position: "relative",
  },
  bubbleChatIntro: {
    position: "absolute",
    backgroundColor: "transparent",
    border: "none",
    width: "200px",
    height: "fit-content",
    top: "15px",
    animation: "$show 0.5s",
  },
  bubbleChatBtn: {
    all: "unset",
    position: "absolute",
    cursor: "pointer",
    width: "70px",
    height: "70px",
    backgroundColor: "transparent",
    border: "none",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    right: 0,
    "&:hover": {
      backgroundColor: "none" ,
      border: 'none',
      background: 'none',
      outline: "none",
    },
    "&:focus": {
      backgroundColor: "none",
      outline: "none",
      border: 'none',
      background: 'none',
    },
  },
  "@keyframes show": {
    from: { clipPath: "inset(0 0 0 100%)" },
    to: { clipPath: "inset(0 0 0 0)" },
  },
}));
