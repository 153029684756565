import { useCountDown } from "ahooks";
import { useState, useEffect } from "react";
import { ChatEvents, DRAID_CUSTOM_EVENT } from "../../../types";

export function useChatWarning() {
  const [open, setOpen] = useState(false);
  const [targetDate, setTargetDate] = useState<number>();

  const [] = useCountDown({
    targetDate,
    onEnd: () => setOpen(true),
  });

  useEffect(() => {
    async function handleEvent(event: any) {
      const { command } = event.detail;
      if (
        command === ChatEvents.USER_SEND_MESSAGE ||
        command === ChatEvents.SELECT_PATIENT ||
        command === ChatEvents.SELECT_VISIT ||
        command === ChatEvents.USER_SEND_MESSAGE_V4
      ) {
        setTargetDate(Date.now() + 7000);
      } else if (
        command === ChatEvents.NEW_MESSAGE ||
        command === ChatEvents.NEW_MESSAGE_REFERENCE ||
        command === ChatEvents.NEW_MESSAGE_STREAM ||
        command === ChatEvents.COMPLETE_MESSAGE_STREAM ||
        command === ChatEvents.MESSAGE_STREAM_ERROR
      ) {
        setTargetDate(undefined);
        setOpen(false);
      }
    }
    document.addEventListener(DRAID_CUSTOM_EVENT, handleEvent);
    return () => {
      document.removeEventListener(DRAID_CUSTOM_EVENT, handleEvent);
    };
  }, []);

  return { open };
}
