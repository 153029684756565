import React, {useEffect, useState} from 'react';
import {ChartData} from '../../types';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    TimeScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import 'chartjs-adapter-moment';
import {Line} from 'react-chartjs-2';
import ColorUtils from "./ColorUtils";

ChartJS.register(
    CategoryScale,
    LinearScale,
    TimeScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

export interface LineChartProps {
    data: ChartData[];
}

export function LineChart({data}: LineChartProps) {
    const isEN = localStorage.getItem("NG_TRANSLATE_LANG_KEY") === "en";
    const [colors, setColors] = useState([""])
    useEffect(() => {
        setColors(ColorUtils.generateRandomColorsArray(data.length))
    }, [data]);

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
            display: false
        },
        plugins: {
            title: {
                display: true,
                text: isEN? "Line chart" : "Biểu đồ đường",
            },
        },

    };

    const lineChartData = {
        labels: data.map((item) => item.name),
        datasets: [
            {
                label: "",
                data: data.map((item) => item.value),
                backgroundColor: "#00a99d",
                borderColor: "#00a99d",
                borderWidth: 3,
                hoverOffset: 8
            },
        ],
    }
    return <Line options={options} data={lineChartData}/>;
}

export default LineChart;
