import { ReactNode } from "react";

export enum MessageTypeEnum {
  NUMBER = "NUMBER",
  PIE_CHART = "PIE",
  LINE_CHART = "LINE",
  COLUMN_CHART = "COLUMN",
}

export interface AnswerChunk {
  id: string;
  openingQuote: string;
  chartType: string;
  chartData: ChartData[];
  summarize: string;
}

export interface ChartData {
  name: string;
  value: number;
}

export interface IChatOptions {
  anchorElement?: ReactNode;
  open: boolean;
  displayMode: "bubble" | "fullscreen" | "custom";
  width?: number;
  height?: number;
  user: {
    displayName: string;
    avatar?: string;
  };
  apiEndPoints: {
    sendMessage(
      message: string,
      previousMessages?: IPreviousMessage[],
      sender?: { id: string; name: string }
    ): Promise<any>;
  };
}

export interface IChatUser {
  id: string;
  name: string;
  isMe: boolean;
}

export interface IChatMessage {
  id: string;
  text: string;
  sender: IChatUser;
  sendAt: Date;
  isCompleted?: boolean;
  type?: string;
  messages?: string[];
  chartData?: ChartData[];
  summarize?: string;
}

export interface IPreviousMessage {
  message: string;
  isMe: boolean;
  sendAt: Date;
}

export interface IAnswer {
  answer: string;
  ask: string;
  responseAt: number;
}
