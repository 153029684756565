import React from 'react';
import { IChatUser } from '../../types';
import { useStyles } from './SenderAvatar.styles';
import ChatBotAvatar from '../icons/chat-bot-avatar';

export interface SenderAvatarProps {
  sender: IChatUser;
  showAvatar: boolean;
}

export function SenderAvatar({ sender, showAvatar }: SenderAvatarProps) {
  const styles = useStyles({ showAvatar });
  if (sender.isMe) return null;
  return (
    <div className={styles.container}>
      {showAvatar && <ChatBotAvatar/>}
    </div>
  );
}

export default SenderAvatar;
