import { ChatEvents, DRAID_CUSTOM_EVENT } from "../../types";
import { IChatMessage } from "../types";

const participants = [
  { id: "senme-chatbot", name: "DrAid Copilot Chatbot", isMe: false },
];

function dispatchEvent(command: string, data: IChatMessage, metadata?: any) {
  try {
    const eventData = { command, data, metadata };
    const eventDetail = { detail: eventData };
    const chatResponseEvent = new CustomEvent(DRAID_CUSTOM_EVENT, eventDetail);
    document.dispatchEvent(chatResponseEvent);
  } catch (error) {}
}

function addMessageStream(data: IChatMessage) {
  return dispatchEvent(ChatEvents.NEW_MESSAGE_STREAM, data);
}

function completeMessageStream(data: IChatMessage) {
  return dispatchEvent(ChatEvents.COMPLETE_MESSAGE_STREAM, data);
}

function addMessage(data: IChatMessage) {
  return dispatchEvent(ChatEvents.NEW_MESSAGE, data);
}

function scrollToEnd() {
  return dispatchEvent(ChatEvents.SCROLL_TO_END, {
    id: "scroll-to-end-" + Date.now(),
    text: "",
    sendAt: new Date(),
    sender: participants[0],
  });
}

export const chatEventUtils = {
  participants,
  dispatchEvent,
  addMessage,
  addMessageStream,
  completeMessageStream,
  scrollToEnd,
};
