import { useEffect, useRef } from "react";
import { ChatMessage } from "../chat_message/ChatMessage";
import { useChatContext } from "../chat_provider/ChatModuleContext";
import { useStyles } from "./ChatMessages.styles";
import { useTranslation } from "../../i18n";
import { ChatEvents, DRAID_CUSTOM_EVENT } from "../../../types";

export function ChatMessages() {
  const { messages, loading } = useChatContext();
  const styles = useStyles();
  const containerRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const isMouseDownRef = useRef<boolean>(false);
  const isMouseWheelRef = useRef<boolean>(false);

  useEffect(() => {
    const handleModuleChatEvent = (event: any) => {
      if (!containerRef.current) return;
      const { command } = event.detail;
      const { scrollHeight, scrollTop, clientHeight } = containerRef.current;
      if (command === ChatEvents.SCROLL_TO_END) {
        isMouseWheelRef.current = false;
        setTimeout(() => {
          containerRef.current?.scrollTo({
            top: scrollHeight,
            behavior: "smooth",
          });
        }, 100);
        return;
      } else if (command === ChatEvents.USER_SEND_MESSAGE) {
        isMouseWheelRef.current = false;
        setTimeout(() => {
          containerRef.current?.scrollTo({
            top: scrollHeight,
            behavior: "smooth",
          });
        }, 100);
        return;
      } else if (command === ChatEvents.COMPLETE_MESSAGE_STREAM) {
        document.getElementById("input-chat-gpt")?.focus();
        isMouseWheelRef.current = false;
      }

      if (!isMouseDownRef.current && !isMouseWheelRef.current) {
        const diff = scrollHeight - clientHeight - scrollTop;
        if (diff > 0 && diff < 120) {
          containerRef.current.scrollTo({
            top: scrollHeight,
            behavior: "smooth",
          });
        }
      }
    };

    document.addEventListener(DRAID_CUSTOM_EVENT, handleModuleChatEvent);

    return () => {
      document.removeEventListener(DRAID_CUSTOM_EVENT, handleModuleChatEvent);
    };
  }, [containerRef, isMouseDownRef]);

  return (
    <div
      ref={containerRef}
      className={styles.container}
      onMouseDown={() => (isMouseDownRef.current = true)}
      onMouseUp={() => (isMouseDownRef.current = false)}
      onWheel={(evt) => (isMouseWheelRef.current = evt.deltaY < 0)}
      onTouchStart={(evt) => {
        evt.stopPropagation();
        evt.preventDefault();
      }}
    >
      {loading && <div className={styles.loading}>{t("draidChatLoading")}</div>}
      {messages.map((message, index) => (
        <ChatMessage
          key={message.id}
          message={message}
          showAvatar={
            messages[index + 1] !== null &&
            message.sender !== messages[index + 1]?.sender
          }
        />
      ))}
    </div>
  );
}

export default ChatMessages;
