import { KeyboardEvent, useEffect, useRef, useState } from "react";
import { useChatContext } from "../chat_provider/ChatModuleContext";
import { useStyles } from "./ChatInput.styles";
import ChatTyping from "../chat_typing/ChatTyping";
import ChatBotAvatar from "../icons/chat-bot-avatar";
import { useTranslation } from "../../i18n";
import React from "react";
import { MdKeyboardVoice } from "react-icons/md";
import { IconButton, Tooltip } from "@material-ui/core";
import VoiceInput from "./VoiceInput";
import { VscSend } from "react-icons/vsc";
import { useChatWarning } from "./useChatWarning";
import ChatSuggesstion from "./ChatSuggestion";
import { chatEventUtils } from "../../utils/chat-event-utils";
import { FaQuestion } from "react-icons/fa6";

export function ChatInput() {
  const { sending, sendMessage } = useChatContext();
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const [disabled, setDisabled] = useState(true);
  const styles = useStyles();
  const { t } = useTranslation();
  const { open } = useChatWarning();
  const [useVoiceInput, setUseVoiceInput] = useState(false);
  const [showSuggesstion, setShowSuggestion] = useState(false);

  function onClickSendMessage() {
    if (!inputRef.current) return;
    const message = inputRef.current?.value;
    sendMessage(message);
    inputRef.current.value = "";
    inputRef.current.focus();
    inputRef.current.style.height = "40px";
    setDisabled(true);
  }

  function handleKeydown(event: KeyboardEvent) {
    if (sending || disabled) return;
    if (event.key === "Enter" && !event.shiftKey) {
      onClickSendMessage();
      event.preventDefault();
      event.stopPropagation();
    }
  }

  function onInputChange() {
    if (!inputRef.current) return;
    setDisabled(inputRef.current.value.trim().length === 0);
    inputRef.current.style.height = "40px";
    inputRef.current.style.height = `${inputRef.current.scrollHeight}px`;
  }

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  useEffect(() => {
    const handleModuleChatEvent = (event: any) => {
      const { command } = event.detail;
      if (command === "COMPLETE_MESSAGE_STREAM" && inputRef.current) {
        inputRef.current.focus();
      }
    };

    document.addEventListener("MODULE_CHAT_EVENT", handleModuleChatEvent);

    return () => {
      document.removeEventListener("MODULE_CHAT_EVENT", handleModuleChatEvent);
    };
  }, [inputRef]);

  return (
    <div className={styles.container}>
      {!showSuggesstion && (
        <Tooltip
          arrow
          title="Câu hỏi mặc định"
          PopperProps={{
            style: { zIndex: 99999 },
            disablePortal: true,
          }}
          placement="left"
        >
          <button
            className={styles.btnShowSuggestion}
            onClick={(evt) => {
              evt.preventDefault();
              evt.stopPropagation();
              setShowSuggestion(true);
            }}
          >
            <FaQuestion size={16} color="#fff" />
          </button>
        </Tooltip>
      )}
      {showSuggesstion && !sending && (
        <ChatSuggesstion
          onSelect={(text) => {
            sendMessage(text);
            setShowSuggestion(false);
          }}
          onClose={() => setShowSuggestion(false)}
        />
      )}
      <div className={styles.inputContainer}>
        <textarea
          onKeyDown={handleKeydown}
          onChange={onInputChange}
          onFocus={() => {
            chatEventUtils.scrollToEnd();
          }}
          ref={inputRef}
          placeholder={t("chatPlaceholder")}
          className={styles.input}
          id="input-chat-gpt"
        />
        <IconButton
          className={styles.btnVoiceChat}
          onClick={() => {
            if (inputRef.current) inputRef.current.value = "";
            setUseVoiceInput((pre) => !pre);
          }}
        >
          <MdKeyboardVoice />
        </IconButton>

        <button
          type="button"
          className={styles.button}
          onClick={onClickSendMessage}
          disabled={disabled || sending || useVoiceInput}
        >
          {disabled || sending ? <VscSend /> : <VscSend />}
        </button>

        {sending && (
          <div className={styles.sendingBox}>
            <ChatBotAvatar />
            <ChatTyping openWarning={open} />
          </div>
        )}
      </div>
      {useVoiceInput && (
        <VoiceInput
          onSubmit={(text: string) => {
            if (inputRef.current && text) {
              inputRef.current.value = text;
              setDisabled(text.trim().length === 0);
            }
          }}
          onClose={() => setUseVoiceInput(false)}
        />
      )}
    </div>
  );
}

export default ChatInput;
