import './App.css';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import GlobalStyles from '@mui/material/GlobalStyles';
import {Box} from "@mui/material";
import React, {useEffect, useState} from "react";
import Header from "./components/Header";
import Loading from "./components/Loading";
import {models} from "powerbi-client";
import {PowerBIEmbed} from "powerbi-client-react";
import axios from "axios";
import Error from "./components/Error";
import ChatGPT from "./chatgpt/ChatGPT";

const theme = createTheme({
    components: {
        MuiMenu: {
            styleOverrides: {
                list: {
                    '&[role="menu"]': {
                        backgroundColor: '#0C1123',
                        color: '#FFFFFF'
                    },
                },
            },
        },
    },
});

const App = (props) => {
    const {tab} = props
    const [hasError, setHasError] = useState(false)
    const [loading, setLoading] = useState(true)
    const [embedReport, setEmbedReport] = useState({})

    useEffect(() => {
        console.log("CHANGED TAB: " + tab)
        getEmbedReport().then(() => console.log('DONE'))
    }, [tab]);

    const getEmbedReport = () => {
        console.log('Embed Report: GETTING');
        setLoading(true)

        return axios
            .post(`${process.env.REACT_APP_DASHBOARD_API_URI}/api/power-bi/embed-report/${tab}`)
            .then(resp => {
                setEmbedReport(resp.data)
                setLoading(false)
            })
            .catch(e => {
                setHasError(true)
                setLoading(false)
                console.error(e)
            })
    };

    return (
        <ThemeProvider theme={theme}>
            <GlobalStyles styles={{ul: {margin: 0, padding: 0, listStyle: 'none'}}}/>
            <CssBaseline/>
            <Header tab={tab}/>
            <Box
                component="main"
                style={{
                    backgroundColor: '#132635',
                    height: 'calc(100.0vw)',
                }}
            >
                {
                    hasError && <Error/>
                }
                {
                    !hasError &&
                    (
                        loading
                            ? <Loading/>
                            : <PowerBIEmbed
                                embedConfig={{
                                    type: 'report',
                                    id: embedReport.reportId,
                                    embedUrl: embedReport.embedUrl,
                                    accessToken: embedReport.embedToken.token,
                                    tokenType: models.TokenType.Embed,
                                    settings: {
                                        panes: {
                                            filters: {
                                                expanded: false,
                                                visible: false,
                                            },
                                            pageNavigation: {
                                                visible: false,
                                            },
                                        },
                                    }
                                }}
                                eventHandlers={
                                    new Map([
                                        ['loaded', () => console.log('Report loaded')],
                                        ['rendered', () => console.log('Report rendered')],
                                        ['error', (event) => console.log(event.detail)],
                                        ['visualClicked', () => console.log('Visual clicked')],
                                        ['pageChanged', event => {
                                            const page = event.detail.newPage;
                                            console.log(page.name + " - " + page.displayName);
                                        }],
                                    ])
                                }
                                cssClassName={"Embed-container"}
                                getEmbeddedComponent={(embeddedReport) => {
                                    window.report = embeddedReport;
                                }}
                            />
                    )
                }
                <ChatGPT fullName='Chat GPT'/>
            </Box>
        </ThemeProvider>
    );
}

export default App;
