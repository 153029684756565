import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(() => ({
  voiceChatContainer: {
    borderTop: "solid 1px #e2e8f0",
    padding: 16,
    height: "8rem",
    overflowY: "auto",
    overflowX: "hidden",
  },

  voiceChatData: {
    width: "calc(100% - 20px)",
    background: "#f0f5f6",
    border: "none",
    outline: "none",
    minHeight: "4rem",
    padding: "12px",
    marginTop: 16,
    marginBottom: 16,
  },

  voiceChatHeader: {
    display: "flex",
    alignItems: "center",
    gap: 6,
  },
  recordingBox: {
    width: 8,
    height: 8,
    borderRadius: 4,
    background: "gray",
  },
  button: {
    all: "unset",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 32,
    height: 32,
    borderRadius: 20,
    color: "#000",
    cursor: "pointer",
    opacity: 1,
    border: "none",
    backgroundColor: "none",
    flexGrow: 0,
  },
  recordingBoxAnimation: {
    background: "red",
    animation: `$recordingEffect 1s infinite`,
  },
  "@keyframes recordingEffect": {
    "0%": {
      opacity: 0,
    },
    "50%": {
      opacity: 1,
    },
    "100%": {
      opacity: 0,
    },
  },
}));
