import { IChatMessage, IChatOptions, IChatUser } from '../../types';

export interface ChatModuleState {
  open: boolean;
  loading: boolean;
  sending: boolean;
  typing: boolean;

  options: IChatOptions;
  messages: IChatMessage[];
  participants: IChatUser[];
  toggleChat(): void;
  sendMessage(text: string): void;
  onMessagesLoaded(data: {
    messages: IChatMessage[];
    participants: IChatUser[];
  }): void;
  onNewMessage(message: IChatMessage): void;
}

export const defaultChatModuleState = {} as ChatModuleState;
