import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(() => ({
  container: {
    backgroundColor: "#fff",
    position: "relative",
  },
  inputContainer: {
    backgroundColor: "#fff",
    position: "relative",
    display: "flex",
    alignItems: "center",
    padding: "10px 16px",
    borderTop: "solid 1px #e2e8f0",
  },
  input: {
    backgroundColor: "#fff",
    width: "100%",
    outline: 0,
    fontSize: 18,
    fontWeight: 400,
    color: "#334155",
    border: 0,
    borderRadius: 8,
    padding: 8,
    background: "#f2f2f2",
    lineHeight: "22px",
    "&:disabled": {
      opacity: 0.4,
    },
    maxHeight: 200,
    minHeight: 40,
    height: "40px",
    fontFamily: "system-ui",
  },
  button: {
    all: "unset",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 40,
    height: 40,
    borderRadius: 20,
    color: "#0db1f0",
    cursor: "pointer",
    opacity: 1,
    border: "none",
    backgroundColor: "none",
    flex: "none",
    "&:disabled": {
      color: "#d1d5db",
      cursor: "not-allowed",
      opacity: 0.6,
    },
  },
  sendingBox: {
    position: "absolute",
    display: "flex",
    bottom: "100%",
    alignItems: "flex-end",
    paddingBottom: 12,
  },
  btnVoiceChat: {
    all: "unset",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 36,
    height: 36,
    borderRadius: 20,
    fontSize: 16,
    color: "#000",
    cursor: "pointer",
    opacity: 1,
    border: "none",
    backgroundColor: "none",
    flex: "none",
    position: "relative",
    "&:disabled": {
      color: "#d1d5db",
      cursor: "not-allowed",
      opacity: 0.6,
    },
    marginRight: 6,
  },
  btnShowSuggestion: {
    all: "unset",
    background: "linear-gradient(90deg, #00A99D 0%, #005BAA 100%)",
    position: "absolute",
    right: 16,
    bottom: "100%",
    marginBottom: 16,
    width: 32,
    height: 32,
    overflow: "hidden",
    borderRadius: 24,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
  },
}));
