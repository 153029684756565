import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    alignItems: "center",
    height: 64,
    gap: 8,
    color: "#fff",
    borderBottom: "solid 1px #ccc",
    background: "linear-gradient(90deg, #00A99D 0%, #005BAA 100%)",
    padding: "0 8px",
    paddingRight: 16,
  },
  logo: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 40,
    height: 40,
    borderRadius: 16,
  },
  title: {
    fontSize: 18,
    fontWeight: 500,
    flexGrow: 1,
    color: "#fff",
  },
  close: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 40,
    height: 40,
    borderRadius: 16,
    border: 0,
    background: "none",
    padding: 0,
    "&:hover": {
      cursor: "pointer",
    },
  },
  headerBtn: {
    all: "unset",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 32,
    height: 32,
    "&:hover": {
      cursor: "pointer",
      background: "none",
    },
  },
}));
