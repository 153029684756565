/*
 * File: index.ts
 * Project: draid-chat
 * File Created: Wednesday, 19th July 2023 10:45:49 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2023 VinBrain JSC
 */

import { en } from "./en";
import { I18Resource } from "./type";
import { vi } from "./vi";
const SUPPORTED_LOCALES = ["vi", "en"];
export function getCurrentLocale(defaultLocale = "vi") {
  try {
    const item = localStorage.getItem("NG_TRANSLATE_LANG_KEY");
    if (item && SUPPORTED_LOCALES.includes(item.toLowerCase())) {
      return item.toLowerCase();
    }
  } catch (error) {}
  return defaultLocale;
}

export function getResource(locale?: string) {
  let currentLocale = locale;
  if (!currentLocale) {
    try {
      const item = localStorage.getItem("NG_TRANSLATE_LANG_KEY");
      if (item) currentLocale = item;
    } catch (error) {}
  }
  let resource: I18Resource;
  if (currentLocale?.toString() === "vi") {
    resource = vi;
  } else if (currentLocale?.toString() === "en") {
    resource = en;
  } else {
    resource = vi;
  }
  return resource;
}

export function useTranslation(locale?: string) {
  const resource: I18Resource = getResource(locale);
  return {
    t: (key: keyof I18Resource, params?: Record<string, string>) => {
      let str = resource[key] ?? "";
      if (params) {
        for (const paramKey of Object.keys(params)) {
          str = str.replace(`{${paramKey}}`, params[paramKey]);
        }
      }
      return str;
    },
  };
}
