import React from "react";

import { useStyles } from "./ChatTyping.styles";
interface Props {
  openWarning?: boolean;
}
export default function ChatTyping({ openWarning }: Props) {
  const styles = useStyles();
  return (
    <div className={styles.threeDotBox}>
      {openWarning && (
        <div
          style={{
            padding: "8px 16px",
            maxWidth: 360,
            color: "#3e3e3e",
            display: "flex",
            gap: 8,
          }}
        >
          <span>Copilot đang xử lý thông tin</span>
          <div style={{ display: "flex", gap: 6, paddingTop: 12 }}>
            <div className={styles.dot1} />
            <div className={styles.dot2} />
            <div className={styles.dot3} />
          </div>
        </div>
      )}
      {!openWarning && (
        <div className={styles.threeDot}>
          <div className={styles.dot1} />
          <div className={styles.dot2} />
          <div className={styles.dot3} />
        </div>
      )}
    </div>
  );
}
