import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(() => ({
  btnCloseContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: 4,
    color: "#ccc",
    "&:hover": {
      cursor: "pointer",
    },
  },
  btnClose: {
    margin: "auto",
  },
  suggestionItemContainer: {
    border: "solid 1px #e2e8f0",
    padding: "6px 12px",
    margin: "8px 12px 12px 12px",
    borderRadius: 12,
    background: "#F8F8F8",
    display: "flex",
    alignItems: "center",
    gap: 16,
    cursor: "pointer",
    "&:hover": {
      background: "#fff",
    },
  },
  suggestionItemText: {
    flexGrow: 1,
  },
  suggestionItemButton: {
    flexGrow: 0,
    color: "#ccc",
  },
}));
