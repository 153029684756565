/*
 * File: en.ts
 * Project: draid-chat
 * File Created: Wednesday, 19th July 2023 10:44:48 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2023 VinBrain JSC
 */

export const en = {
  draidChatBot: "DrAid™ Copilot",
  welcomeMessage: "Welcome to DrAid™ Copilot! How can I help you?",
  chatPlaceholder: "Enter a message",
  draidTyping: "DrAid™ is typing",
  draidChatLoading: "Loading data...",
  chat_gpt_disclaimer:
    "The information provided by the Chatbot is for reference only",
  message_intro: "Can I help you ?",
  chat_gpt_disclaimer_short:
    "Information is for reference only, please ask experts for further advise",

  error_message:  "There is an error occurred! Please try again later",
  summarization_header: "SUMMARY OF PATIENT INFORMATION",
  tooltip_copy:  "Copy",
  tooltip_copied:  "Copied",
  tooltip_like:  "Like",
  tooltip_dislike:  "Dislike",

};
