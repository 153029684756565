import { makeStyles } from '@material-ui/core/styles';

const speed: string = '1.5s';

export const useStyles = makeStyles(() => ({
  threeDotBox: {
    background: 'linear-gradient(to right, #ffffff , #A7FFF5)',
    borderRadius: '22.1818px 22.1818px 22.1818px 0px',
    display: 'flex',
    alignItems: 'flex-end'
  },
  threeDot: {
    backgroundColor: '#ffffff',
    borderRadius: '22.1818px 22.1818px 22.1818px 0px',
    padding: 14,
    fontSize: 14,
    fontStyle: 'italic',
    pointerEvents: 'none',
    color: '#6b7280',
    display: 'flex',
    width: 50,
    justifyContent: 'space-between'
  },
  dot1: {
    height: '5px',
    width: '5px',
    borderRadius: '5px',
    background: '#00A99D',
    animation: `$blink ${speed} infinite`
  },
  dot2: {
    height: '5px',
    width: '5px',
    borderRadius: '5px',
    background: '#00A99D',
    animationDelay: '0.2s',
    animation: `$blink ${speed} infinite`
  },
  dot3: {
    height: '5px',
    width: '5px',
    borderRadius: '5px',
    background: '#00A99D',
    animatioDelay: '0.4s',
    animation: `$blink ${speed} infinite`
  },
  typing: {
    position: 'relative'
  },
  '@keyframes blink': {
    '0%': {
      opacity: 0.1
    },
    '20%': {
      opacity: 1
    },
    '100%': {
      opacity: 0.1
    }
  }
}));
