import { useEffect, useState } from "react";
import {
  AnswerChunk,
  ChartData,
  IAnswer,
  IChatMessage,
  IChatOptions,
  MessageTypeEnum,
} from "../../types";
import { getCurrentLocale, useTranslation } from "../../i18n";
import { fetchEventSource } from "@microsoft/fetch-event-source";

const API_ROOT_ENDPOINT = "https://api.108.draid.ai/copilot";

const DEFAULT_ERR_MESSAGE =
  "There is an error occurred! Please try again later";

export function useChats(options: IChatOptions) {
  const { t } = useTranslation();
  const [participants] = useState([
    { id: "senme-chatbot", name: "DrAid Copilot Chatbot", isMe: false },
    {
      id: options.user.displayName,
      name: options.user.displayName,
      isMe: true,
    },
  ]);

  useEffect(() => {
    const messages: IChatMessage[] = [
      {
        id: "bot_welcome_message",
        text: t("welcomeMessage"),
        sender: participants[0],
        sendAt: new Date(),
      },
      {
        id: "chat_gpt_disclaimer",
        text: t("chat_gpt_disclaimer"),
        sender: participants[0],
        sendAt: new Date(),
      },
    ];
    const event = new CustomEvent("MODULE_CHAT_EVENT", {
      detail: {
        command: "MESSAGES_LOADED",
        data: { messages, participants },
      },
    });
    setTimeout(() => document.dispatchEvent(event), 1000);
  }, [participants, getCurrentLocale()]);

  useEffect(() => {
    async function handleEvent(event: any) {
      const { command, data, previousMessages } = event.detail;
      function appendMessageToChat(
        id: string,
        sendAt: Date,
        openingQuote: string,
        chartData: ChartData[],
        summarize?: string,
        type?: string
      ) {
        let messageType: MessageTypeEnum = MessageTypeEnum.NUMBER;
        if (type === MessageTypeEnum.PIE_CHART.toString().toLowerCase()) {
          messageType = MessageTypeEnum.PIE_CHART;
        } else if (
          type === MessageTypeEnum.LINE_CHART.toString().toLowerCase()
        ) {
          messageType = MessageTypeEnum.LINE_CHART;
        } else if (
          type === MessageTypeEnum.COLUMN_CHART.toString().toLowerCase()
        ) {
          messageType = MessageTypeEnum.COLUMN_CHART;
        }
        try {
          const eventData = {
            command: "NEW_MESSAGE_STREAM",
            data: {
              id: id,
              text:
                openingQuote +
                (messageType === MessageTypeEnum.NUMBER
                  ? " " + (chartData[0].value > -1 ? chartData[0].value : "")
                  : ""),
              chartData:
                messageType === MessageTypeEnum.NUMBER ? [] : chartData,
              sender: participants[0],
              sendAt: sendAt,
              type: messageType,
              summarize: summarize,
            },
          };
          console.log(eventData);
          const chatResponseEvent = new CustomEvent("MODULE_CHAT_EVENT", {
            detail: eventData,
          });
          document.dispatchEvent(chatResponseEvent);
        } catch (error) {}
      }

      function completeMessageToChat(id: string, sendAt: Date) {
        try {
          const eventData = {
            command: "COMPLETE_MESSAGE_STREAM",
            data: {
              id: id,
              text: "",
              sender: participants[0],
              sendAt: sendAt,
              isCompleted: true,
            },
          };
          const chatResponseEvent = new CustomEvent("MODULE_CHAT_EVENT", {
            detail: eventData,
          });
          document.dispatchEvent(chatResponseEvent);
        } catch (error) {}
      }

      function addMessageToChat(id: string, sendAt: Date, answer: IAnswer) {
        try {
          const eventData = {
            command: "NEW_MESSAGE",
            data: {
              id: id,
              text: answer.answer,
              sender: participants[0],
              sendAt: sendAt,
            },
          };
          const chatResponseEvent = new CustomEvent("MODULE_CHAT_EVENT", {
            detail: eventData,
          });
          document.dispatchEvent(chatResponseEvent);
        } catch (error) {}
      }

      if (command === "USER_SEND_MESSAGE") {
        const { text } = data;
        const messageId = Date.now().toString();
        const sendAt = new Date();
        try {
          const url = `${API_ROOT_ENDPOINT}/api/v2/chat`;
          await fetchEventSource(url, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Accept: "text/event-stream",
              language: getCurrentLocale(),
            },
            body: JSON.stringify({
              id: localStorage.getItem("uuid"),
              ask: text,
              isFirst: previousMessages.length === 0,
            }),
            openWhenHidden: true,
            onmessage: (ev: any) => {
              try {
                const answer = JSON.parse(ev.data) as AnswerChunk;
                appendMessageToChat(
                  messageId,
                  sendAt,
                  answer.openingQuote,
                  answer.chartData,
                  answer.summarize,
                  answer.chartType
                );
              } catch (error) {
                console.log("err", error);
              }
            },
            onclose: () => {
              completeMessageToChat(messageId, sendAt);
            },
            onerror: (err) => {
              addMessageToChat(
                messageId,
                sendAt,
                err?.message ?? DEFAULT_ERR_MESSAGE
              );
              throw err;
            },
          });
        } catch (error: any) {
          addMessageToChat(
            messageId,
            sendAt,
            error?.message ?? DEFAULT_ERR_MESSAGE
          );
        }
      }
    }

    document.addEventListener("MODULE_CHAT_EVENT", handleEvent);
    return () => {
      document.removeEventListener("MODULE_CHAT_EVENT", handleEvent);
    };
  }, []);
}
