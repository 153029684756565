import React from 'react';

export function CustomChat() {
  return (
    <div>
      <h1>CustomChat</h1>
    </div>
  );
}

export default CustomChat;
