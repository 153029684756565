import clsx from "clsx";
import {SenderAvatar} from "../sender_avatar/SenderAvatar";
import {useStyles} from "./ChatMessage.styles";
import {useEffect, useMemo, useRef, useState} from "react";
import {useTranslation} from "../../i18n";
import Tooltip from "@material-ui/core/Tooltip";
import {BiDislike, BiLike, BiSolidDislike, BiSolidLike} from "react-icons/bi";
import {ReactMarkdown} from "react-markdown/lib/react-markdown";
import gfm from "remark-gfm";
import {IChatMessage, MessageTypeEnum} from "../../types";
import PieChart from "../chart/PieChart";
import LineChart from "../chart/LineChart";
import ColumnChart from "../chart/ColumnChart";

enum ReactionEnum {
    NONE = "none",
    LIKE = "like",
    DISLIKE = "dislike",
}

export interface ChatMessageProps {
    message: IChatMessage;
    showAvatar: boolean;
}

export function ChatMessage({message, showAvatar}: ChatMessageProps) {
    const styles = useStyles();
    const isMine = message.sender.isMe;
    const [reaction, setReaction] = useState<ReactionEnum>(ReactionEnum.NONE);
    const [isHover, setHover] = useState(false);
    const {t} = useTranslation();
    return (
        <div
            className={clsx(
                isMine ? styles.myContainer : styles.otherContainer,
                "message-item-gpt"
            )}
            id={message.id}
        >
            <SenderAvatar showAvatar={showAvatar} sender={message.sender}/>
            <div
                className={isMine ? styles.myMessageBox : styles.otherMessageBox}
                style={{position: "relative"}}
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
            >
                {isMine && (
                    <div
                        className={styles.myMessage}
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{__html: message.text}}
                    />
                )}

                {!isMine && <ChatBotMessage data={message}/>}

                {!isMine && !message.id.startsWith("bot") && (
                    <div
                        style={{
                            position: "absolute",
                            bottom: -12,
                            right: 4,
                            display: "flex",
                            alignItems: "center",
                        }}
                    >
                        {(isHover || reaction === ReactionEnum.LIKE) && (
                            <Tooltip arrow title={t("tooltip_like")}>
                                <button
                                    style={{
                                        all: "unset",
                                        padding: 4,
                                        borderRadius: 100,
                                        background: "white",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        border: "solid 1px #ccc",
                                        marginRight: 4,
                                    }}
                                    onClick={() => setReaction(ReactionEnum.LIKE)}
                                >
                                    {reaction !== ReactionEnum.LIKE && <BiLike size={16}/>}
                                    {reaction === ReactionEnum.LIKE && (
                                        <BiSolidLike size={16} color="#00A99D"/>
                                    )}
                                </button>
                            </Tooltip>
                        )}
                        {(isHover || reaction === ReactionEnum.DISLIKE) && (
                            <Tooltip arrow title={t("tooltip_dislike")}>
                                <button
                                    style={{
                                        all: "unset",
                                        padding: 4,
                                        borderRadius: 100,
                                        background: "white",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        border: "solid 1px #ccc",
                                    }}
                                    onClick={() => setReaction(ReactionEnum.DISLIKE)}
                                >
                                    {reaction !== ReactionEnum.DISLIKE && <BiDislike size={16}/>}
                                    {reaction === ReactionEnum.DISLIKE && (
                                        <BiSolidDislike size={16} color="#00A99D"/>
                                    )}
                                </button>
                            </Tooltip>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
}

interface ChatBotMessageProps {
    data: IChatMessage;
}

function ChatBotMessage({data}: ChatBotMessageProps) {
    const styles = useStyles();
    const [isReplaced] = useState(false);
    const containerRef = useRef<HTMLDivElement>(null);
    const messageType = useMemo(() => {
        return data.type;
    }, [data]);

    const textMessage = useMemo(() => {
        return data.text ?? "";
    }, [data]);

    const chartData = useMemo(() => {
        return data.chartData ?? [];
    }, [data]);

    const summarize = useMemo(() => {
        return data.summarize ?? "";
    }, [data]);

    useEffect(() => {
        if (!isReplaced) return;
        if (!containerRef.current) return;

        const container = containerRef.current;

        const elements = container.querySelectorAll(".draid-term");
        for (let i = 0; i < elements.length; i++) {
            const element = elements.item(i);
            if (!element) continue;

            element.addEventListener("click", (event) => {
                const popoverElements = document.querySelectorAll(".draid-popover");
                for (let i = 0; i < popoverElements.length; i++) {
                    popoverElements.item(i).remove();
                }
                const popover = document.createElement("div");
                popover.style.zIndex = "999999";
                popover.style.left = `${(event as any).x - 40}px`;
                popover.style.top = `${(event as any).y + 16}px`;
                popover.style.position = `fixed`;
                popover.style.backgroundColor = "black";
                popover.style.color = "white";
                popover.style.padding = "16px 24px";
                popover.style.borderRadius = "8px";
                popover.style.lineHeight = "1.4";
                popover.classList.add("draid-popover");
                const clickHandle = (event: MouseEvent) => {
                    const target = event.target as HTMLElement;
                    if (target.classList.contains("draid-popover")) return;
                    if (target.classList.contains("draid-term")) return;
                    const popoverElements = document.querySelectorAll(".draid-popover");
                    for (let i = 0; i < popoverElements.length; i++) {
                        popoverElements.item(i).remove();
                    }

                    setTimeout(() => {
                        const elementBBox = popover.getBoundingClientRect();
                        if (elementBBox.x + elementBBox.width >= window.innerWidth - 20) {
                            popover.style.left = `${
                                window.innerWidth - 20 - elementBBox.width
                            }px`;
                        }

                        if (elementBBox.y + elementBBox.height >= window.innerHeight - 20) {
                            popover.style.top = `${
                                window.innerHeight - 20 - elementBBox.height
                            }px`;
                        }
                    }, 100);
                };
                document.addEventListener("click", clickHandle);
                document.body.appendChild(popover);
            });
        }
    }, [isReplaced, data, containerRef]);
    return (
        <div
            className={styles.otherMessage}
            ref={containerRef}
        >
            <ReactMarkdown className="draid-markdown" remarkPlugins={[gfm]}>
                {textMessage}
            </ReactMarkdown>
            {messageType === MessageTypeEnum.PIE_CHART && (
                <div className={styles.pieChartContainer}>
                    <PieChart data={chartData}></PieChart>
                </div>
            )}
            {messageType === MessageTypeEnum.COLUMN_CHART && (
                <div className={styles.chartContainer}>
                    <ColumnChart data={chartData}></ColumnChart>
                </div>
            )}
            {messageType === MessageTypeEnum.LINE_CHART && (
                <div className={styles.chartContainer}>
                    <LineChart data={chartData}></LineChart>
                </div>
            )}
            <ReactMarkdown className="draid-markdown" remarkPlugins={[gfm]}>
                {summarize}
            </ReactMarkdown>
        </div>
    );
}
