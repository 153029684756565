import { useTranslation } from "../../../i18n";
import { useStyles } from "./BubbleIntro.styles";

export function BubbleIntro() {
  const { t } = useTranslation();
  const styles = useStyles();
  return (
    <div className={styles.contentParentBox}>
      <div className={styles.contentBox}>
        <div className={styles.content}>{t("message_intro")}</div>
      </div>
    </div>
  );
}

export default BubbleIntro;
