import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  contentParentBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'linear-gradient(90deg, #FFFFFF 0%, #A7FFF5 100%)',
    borderRadius: '15px 0px 0px 15px',
    padding: '1px',
    boxShadow: '2px 4px 40px rgba(238, 238, 238, 0.4)'
  },
  contentBox: {
    borderRadius: '15px 0px 0px 15px',
    borderStyle: 'none',
    background: 'linear-gradient(90deg, #5AB194 0%, #A5CC83 100%)',
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '10px 20px 10px 10px'
  },
  content: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '0px',
    letterSpacing: '0.09px',
    flex: 'none',
    backgroundColor: 'transparent',
    animation: '$move 0.5s',
    padding: 8
  },
  '@keyframes move': {
    '0%': { color: 'transparent' },
    '50%': { color: 'transparent' },
    '100%': { color: '#fff' }
  }
}));
