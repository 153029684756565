import { useState } from "react";
import { chatApi } from "./ChatGPTApi";
import { ModuleChat } from "./components/ModuleChat";

interface ChatGPTProps {
  fullName: string;
}

function ChatGPT({ fullName }: ChatGPTProps) {
  const [open, setOpen] = useState(false);

  if (!fullName || fullName.trim().length === 0) return null;

  return (
    <ModuleChat
      onClose={() => setOpen(false)}
      options={{
        open,
        displayMode: "bubble",
        width: 400,
        height: 400,
        user: {
          displayName: fullName,
          avatar: "",
        },
        apiEndPoints: {
          sendMessage: chatApi.sendChatMessage,
        },
      }}
    />
  );
}

export default ChatGPT;
