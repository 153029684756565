import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { HashRouter, Route, Routes } from "react-router-dom";
import PageNotFound from "./components/PageNotFound";
import { v4 as uuidv4 } from "uuid";

const root = createRoot(document.getElementById("root"));
localStorage.setItem("uuid", uuidv4());
console.log("UUID: " + localStorage.getItem("uuid"));
root.render(
  <HashRouter>
    <Routes>
      <Route path="" element={<App tab="HOME_BV108" />} />
      <Route path="/" element={<App tab="HOME_BV108" />} />
      <Route path="/HOME" element={<App tab="HOME_BV108" />} />
      <Route path="/ADMISSION" element={<App tab="ADMISSION_BV108" />} />
      <Route path="/DISCHARGE" element={<App tab="DISCHARGE_BV108" />} />
      <Route path="/*" element={<PageNotFound />} />
    </Routes>
  </HashRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
