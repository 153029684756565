import React, {useEffect, useState} from 'react';
import {ChartData} from '../../types';
import {Chart as ChartJS, ArcElement, Tooltip, Legend} from 'chart.js';
import {Pie} from 'react-chartjs-2';
import ColorUtils from "./ColorUtils";

ChartJS.register(ArcElement, Tooltip, Legend);

export interface PieChartProps {
    data: ChartData[];
}

export function PieChart({data}: PieChartProps) {
    const [colors, setColors] = useState([""])
    useEffect(() => {
        setColors(ColorUtils.generateRandomColorsArray(data.length))
    }, [data]);
    const options = {
        responsive: true,
        maintainAspectRatio: false,
    };
    const pieChartData = {
        labels: data.map((item) => item.name),
        datasets: [
            {
                data: data.map((item) => item.value),
                backgroundColor: colors,
                borderWidth: 1,
                hoverOffset: 4
            },
        ]
    }
    return <Pie options={options} data={pieChartData}/>;
}

export default PieChart;
