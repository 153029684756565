import {makeStyles} from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
    myContainer: {
        display: 'flex',
        paddingLeft: 16,
        paddingRight: 16,
        marginTop: 16,
        marginBottom: 16,
        gap: 8,
        justifyContent: 'flex-end'
    },
    otherContainer: {
        display: 'flex',
        paddingLeft: 16,
        paddingRight: 16,
        marginTop: 16,
        marginBottom: 16,
        gap: 8,
        justifyContent: 'flex-start',
        alignItems: 'flex-end'
    },
    myMessageBox: {
        background: 'linear-gradient(to right, #ffffff , #A7FFF5)',
        borderRadius: '16px 16px 0px 16px',
        padding: 1
    },
    otherMessageBox: {
        background: 'linear-gradient(to right, #ffffff , #A7FFF5)',
        borderRadius: '16px 16px 16px 0px',
        padding: 1
    },
    myMessage: {
        background: 'linear-gradient(to right, #005BAA , #00A99D,  #00A99C)',
        color: '#fff',
        fontSize: 16,
        fontWeight: 400,
        paddingLeft: 12,
        paddingRight: 12,
        paddingTop: 8,
        paddingBottom: 8,
        borderRadius: '16px 16px 0px 16px',
        position: 'relative'
    },
    otherMessage: {
        backgroundColor: '#fff',
        color: '#0C1123',
        fontSize: 16,
        fontWeight: 400,
        paddingLeft: 12,
        paddingRight: 12,
        paddingTop: 6,
        paddingBottom: 6,
        borderRadius: '16px 16px 16px 0px',
        position: 'relative'
    },
    chartContainer: {
        '& canvas': {
            width: '100%',
            height: '100%',
        }
    },
    pieChartContainer: {
        '& canvas': {
            width: '100%',
            height: '100%',
            maxHeight: '350px'
        }
    }
}));
