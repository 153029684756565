import { useEffect, useRef, useState } from "react";
import { useStyles } from "./VoiceInput.styles";
import React from "react";
import { MdCheck, MdClose } from "react-icons/md";
import { IconButton, Tooltip } from "@material-ui/core";
import {
  RECORDING_STATE,
  useRecordingContext,
} from "../../../feature-speech-to-text";
import clsx from "clsx";
import { useMount, useUnmount } from "ahooks";

interface Props {
  onSubmit(text?: string): void;
  onClose(): void;
}
export function VoiceInput({ onSubmit, onClose }: Props) {
  const styles = useStyles();
  const { transcriptData, recordingState, onRecord, onStop, onReset } =
    useRecordingContext();
  const [recordStartAt, setRecordStartedAt] = useState<Date | undefined>(
    undefined
  );
  const countingRef = useRef<any>();
  const [recordingTime, setRecordingTime] = useState("00:00");

  function onClickApplyRecording() {
    onSubmit && onSubmit(transcriptData);
    onStop();
    onReset();
    clearInterval(countingRef.current);
    setRecordStartedAt(undefined);
    onClose();
  }

  function onClickCloseRecording() {
    onStop();
    onReset();
    onClose();
    clearInterval(countingRef.current);
    setRecordStartedAt(undefined);
  }

  useEffect(() => {
    if (!recordStartAt && recordingState === RECORDING_STATE.RECORDING) {
      setRecordStartedAt(new Date());
    }
  }, [recordingState, recordStartAt]);

  useEffect(() => {
    if (countingRef.current) clearInterval(countingRef.current);
    if (!recordStartAt) return;
    if (recordingState !== RECORDING_STATE.RECORDING) {
      clearInterval(countingRef.current);
      return;
    }
    countingRef.current = setInterval(() => {
      const duration = Math.floor(
        (Date.now() - recordStartAt.getTime()) / 1000
      );
      const seconds = duration % 60;
      const minute = Math.floor(duration / 60);
      const secondsStr = seconds < 10 ? `0${seconds}` : seconds.toString();
      const minuteStr = minute < 10 ? `0${minute}` : minute.toString();
      setRecordingTime(`${minuteStr}:${secondsStr}`);
    }, 1000);
  }, [recordStartAt, recordingState, countingRef]);

  useMount(() => {
    onRecord();
  });

  useUnmount(() => {
    onStop();
    onReset();
    onClose();
    clearInterval(countingRef.current);
    setRecordStartedAt(undefined);
  });

  return (
    <div className={styles.voiceChatContainer}>
      <div className={styles.voiceChatHeader}>
        <span
          className={clsx(
            styles.recordingBox,
            recordStartAt ? styles.recordingBoxAnimation : ""
          )}
        ></span>
        <span>{recordingTime}</span>
        <span style={{ flex: 1 }} />
        <Tooltip title="Xoá" arrow>
          <IconButton onClick={onClickCloseRecording} size="small" className={styles.button}>
            <MdClose size={20} />
          </IconButton>
        </Tooltip>
        <Tooltip title="Xác nhận" arrow>
          <IconButton size="small" onClick={onClickApplyRecording} className={styles.button}>
            <MdCheck size={20} />
          </IconButton>
        </Tooltip>
      </div>
      <textarea
        className={styles.voiceChatData}
        placeholder="Đang lắng nghe..."
        value={transcriptData}
        readOnly
      />
    </div>
  );
}

export default VoiceInput;
