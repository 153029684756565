import React, {useEffect, useState} from 'react';
import {ChartData} from '../../types';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import ColorUtils from "./ColorUtils";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

export interface ColumnChartProps {
    data: ChartData[];
}

export function ColumnChart({data}: ColumnChartProps) {
    const isEN = localStorage.getItem("NG_TRANSLATE_LANG_KEY") === "en";
    const [colors, setColors] = useState([""])
    useEffect(() => {
        setColors(ColorUtils.generateRandomColorsArray(data.length))
    }, [data]);

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false
            },
            title: {
                display: true,
                text: isEN ? "Column chart": "Biểu đồ cột",
            },
        },
    };

    const columnChartData = {
        labels: data.map((item) => item.name),
        datasets: [
            {
                label: "",
                data: data.map((item) => item.value),
                backgroundColor: "#00a99d",
                borderWidth: 1,
                hoverOffset: 4,
                maxBarThickness: 36
            },
        ],
    }
    return <Bar options={options} data={columnChartData} />;
}

export default ColumnChart;
