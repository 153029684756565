import React from "react";
import {useNavigate} from 'react-router-dom';
import logo from "../assets/logo.svg"
import menu from "../assets/menu.svg";
import header from "../assets/header.png";
import icVI from "../assets/vietnam.png";
import icEN from "../assets/us.png";
import Toolbar from "@mui/material/Toolbar";
import AppBar from "@mui/material/AppBar";
import Divider from '@mui/material/Divider';

import {Menu, MenuItem} from "@mui/material";
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import styles from './Header.css';
import Typography from "@mui/material/Typography";

const MenuDivider = () => {
    return (
        <Divider color="#53616D" style={{
            marginLeft: '12px',
            marginRight: '12px'
        }}/>
    )
}

const Header = (props) => {
    const {tab} = props
    const navigate = useNavigate()
    const [open, setOpen] = React.useState(false)
    const [openLanguage, setOpenLanguage] = React.useState(false)
    const [anchorEl, setAnchorEl] = React.useState(null)
    const [anchorLanguageEl, setAnchorLanguageEl] = React.useState(null)

    const handleMenu = (event) => {
        setOpen(true);
        console.log('Open : ' + open)
        setAnchorEl(event.currentTarget)
    };

    const handleMenuLanguage = (event) => {
        setOpenLanguage(true);
        console.log('Open language : ' + open)
        setAnchorLanguageEl(event.currentTarget)
    };

    const handleOnLanguageClick = (lan) => {
        console.log('Tap language: ' + lan)
        const language = localStorage.getItem("NG_TRANSLATE_LANG_KEY");
        setOpenLanguage(false)
        if (lan !== language) {
            if (tab.includes("_EN") && lan === "vi") {
                console.log('New tab: ' + tab.replace("_EN", ""))
                navigate('/' + tab.replace("_EN", ""))
            } else if (!tab.includes("_EN") && lan === "en") {
                console.log('New tab: ' + tab + "_EN")
                navigate('/' + tab + "_EN")
            }
        }
    };

    const handleOnClick = (to) => {
        const language = localStorage.getItem("NG_TRANSLATE_LANG_KEY")
        setOpen(false)
        if (language === 'en') {
            navigate(to + '_EN')
        } else {
            navigate(to)
        }
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleCloseLanguage = () => {
        setOpenLanguage(false);
    };

    const isEN = localStorage.getItem("NG_TRANSLATE_LANG_KEY") === 'en';
    const menuHospitalDataTitle = isEN ? "Hospital data" : "Số liệu bệnh viện";
    const menuDrAidDataTitle = isEN ? "DrAid data" : "Số liệu DrAid";
    const menuVITitle = isEN ? "Vietnamese" : "Tiếng Việt";
    const menuENTitle = isEN ? "English" : "Tiếng Anh";
    return (
        <AppBar
            position="sticky"
            color="default"
            elevation={0}
            sx={{borderBottom: (theme) => `1px solid ${theme.palette.divider}`}}
            style={{
                backgroundColor: '#0D1D2A',
                backgroundImage: `url(${header})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'auto',
            }}
        >
            <Toolbar sx={{flexWrap: 'wrap'}}>
                <Typography
                    component="h2"
                    variant="h5"
                    color="inherit"
                    align="center"
                    noWrap
                    sx={{flex: 1}}
                    style={{}}
                >
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-around',
                            marginRight: '12px',
                            marginLeft: '12px',
                            height: '60px',
                        }}>
                            <img
                                src={logo}
                                alt="logo"
                                style={{
                                    height: '100px',
                                    width: '100px',
                                    marginRight: '10px',
                                }}
                            />
                        </div>
                        <div style={{
                            width: '1px',
                            height: '50px',
                            background: 'linear-gradient(180deg, rgba(196, 196, 196, 0) 5.17%, #ffffff 45.12%, rgba(196, 196, 196, 0) 100%)',
                        }}/>
                        <div style={{
                            marginLeft: '20px',
                            fontFamily: 'Inter',
                            fontStyle: 'normal',
                            fontWeight: '500',
                            fontSize: '16px',
                            color: '#82cfff',
                            marginBottom: '10px',
                        }}>
                            DrAid™ - ENTERPRISE DATA SOLUTION 
                        </div>
                    </div>
                </Typography>
                {/* Change Language Menu */}
                <IconButton
                    color="info"
                    size="small"
                    onClick={handleMenuLanguage}
                >
                    <img src={isEN ? icEN : icVI}
                         alt="menu-language"
                         className={styles.menuLanguage}
                    />
                </IconButton>
                <Menu
                    id="menu-language-appbar"
                    keepMounted
                    anchorEl={anchorLanguageEl}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={openLanguage}
                    onClose={handleCloseLanguage}
                >
                    <MenuItem selected={!isEN} onClick={() => handleOnLanguageClick("vi")} value={'vi'}><p
                        role="img">{menuVITitle} 🇻🇳</p></MenuItem>
                    <MenuDivider/>
                    <MenuItem selected={isEN} onClick={() => handleOnLanguageClick("en")} value={'en'}><p
                        role="img">{menuENTitle} 🇺🇸</p></MenuItem>
                </Menu>
                {/* DrAid/Hospital Menu */}
                <Button
                    color="info"
                    size="small"
                    onClick={handleMenu}
                    style={{}}
                >
                    <img src={menu}
                         alt="menu"
                         className={styles.menu}
                    />
                </Button>

                <Menu
                    id="menu-appbar"
                    keepMounted
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={open}
                    onClose={handleClose}
                >
                    <MenuItem onClick={() => handleOnClick("/HOME")}>{menuHospitalDataTitle}</MenuItem>
                    <MenuDivider/>
                    <MenuItem onClick={() => handleOnClick("/DRAID")}>{menuDrAidDataTitle}</MenuItem>
                </Menu>
            </Toolbar>
        </AppBar>
    );
};

export default Header;