import { useState } from "react";
import { useStyles } from "./Bubble.styles";
import { BubbleIntro } from "./BubbleIntro";
import ChatBotIcon from "../../../components/icons/chat-bot-icon";

export interface BubbleProps {
  onClick(): void;
  dragging: boolean;
  open: boolean;
  setDisabledDragging: Function;
}
export function Bubble({
  onClick,
  dragging,
  open,
  setDisabledDragging,
}: BubbleProps) {
  const styles = useStyles();
  const [showIntro, setShowIntro] = useState(false);
  return (
    <div
      className={styles.bubbleChatContainer}
      style={{
        width: showIntro && !dragging && !open ? "250px" : "fit-content",
      }}
    >
      {showIntro && !dragging && !open && (
        <div className={styles.bubbleChatIntro}>
          <BubbleIntro />
        </div>
      )}
      <button
        onClick={() => {
          if (dragging === false) {
            setShowIntro(() => false);
            onClick();
          }
        }}
        type="button"
        className={styles.bubbleChatBtn}
        onMouseEnter={() => {
          if (open === false) {
            setShowIntro(true);
          }
        }}
        onMouseLeave={() => {
          if (open === false) {
            setShowIntro(false);
          }
        }}
        onMouseOver={() => {
          setDisabledDragging(false);
        }}
        onMouseOut={() => {
          setDisabledDragging(true);
        }}
      >
        <ChatBotIcon />
      </button>
    </div>
  );
}

export default Bubble;
