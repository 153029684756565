import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  container: {
    flex: 1,
    overflowY: 'auto',
    paddingBottom: 24
  },
  loading: {
    padding: 40,
    textAlign: 'center'
  }
}));
